<template>
	<div class="grid p-fluid formgrid">
		<div class="col-12 lg:col-12 mb-2">
			<Panel header="Filtrar" :toggleable="true" :collapsed="true">
					<div class="field formgrid grid p-fluid">
						<div class="col-3 lg:col-3 mt-7">
							<label for="proveedor" class="mb-2"><strong>Proveedor</strong></label>
							<Dropdown v-model="select_proveedor" :showClear="true" :options="customers_group" optionLabel="nombre_empresa" placeholder="Seleccione un Campo" />
						</div>
						<div class="col-3 lg:col-3 pt-1">
							<div class="pt-1">
								<SelectButton v-model="value1" :options="options" aria-labelledby="single" />
							</div>
							
							<!-- <Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="ml-3 mr-1 mb-2 mt-1">Rango</Checkbox> -->
							<div class="mt-2 pt-1">
								<template v-if="value1 == 'Mes y Año'">
								
									<label for="proveedor" class="ml-2"><strong>Mes y Año</strong></label>
									<Calendar v-model="filtro_mes" :showTime="false" :showButtonBar="true"  view="month" :showIcon="true" dateFormat="mm/yy" :locale="es" class="ml-2" placeholder="Seleccione Mes y Año"/>
								</template>
								<template v-if="value1 == 'Año'">
									<label for="proveedor" class="ml-2"><strong>Año</strong></label>
									<Calendar v-model="filtro_mes" :showTime="false" :showButtonBar="true"  view="year" :showIcon="true" dateFormat="yy" :locale="es" class="ml-2" placeholder="Seleccione Año"/>
								</template>
							</div>
							
						</div>
						<div class="col-3 lg:col-3 mt-7">
							<!-- <template v-if="value1 == 'Mes y Año'">
								
								<label for="proveedor" class="ml-2"><strong>Mes</strong></label>
								<Calendar v-model="filtro_mes" :showTime="false" :showButtonBar="true"  view="month" :showIcon="true" dateFormat="mm/yy" :locale="es" class="ml-2" placeholder="Seleccione Mes y Año"/>
							</template>
							<template v-if="value1 == 'Año'">
								<label for="proveedor" class="ml-2"><strong>Año</strong></label>
								<Calendar v-model="filtro_mes" :showTime="false" :showButtonBar="true"  view="year" :showIcon="true" dateFormat="yy" :locale="es" class="ml-2" placeholder="Seleccione Año"/>
							</template> -->
						</div>
						<!-- <div class="col-3 lg:col-3 mt-7">
							<template v-if="frmfiltros.rangofechasolicitud">
								<template v-if="value1 == 'Mes y Año'">
									<label for="fecha_fin"><strong>Mes y Año final</strong></label>
									<Calendar v-model="filtro_mes_rango" :showTime="false" :showButtonBar="true"  view="month" :minDate="filtro_mes" :showIcon="true" dateFormat="mm/yy" :locale="es" class="ml-2" placeholder="Seleccione Mes y Año Fin"/>
								</template>
								<template v-if="value1 == 'Año'">
									<label for="proveedor"><strong>Año final</strong></label>
									<Calendar v-model="filtro_mes_rango" :showTime="false" :showButtonBar="true" :minDate="filtro_mes"  view="year" :showIcon="true" dateFormat="yy" :locale="es" class="ml-2" placeholder="Seleccione Año"/>
								</template>
							</template>
						</div> -->
						<div class="col-3 lg:col-3">
							<Button type="button" class="mb-4 py-3 p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loader	" @click="limpiar" />
							<Button type="button" class=" py-3 p-button-raised" label="Buscar" icon="pi pi-search" :loading="loader	" @click="cargar_empresas" />
						</div>
					</div>
				</Panel>
		</div>
		<template v-if="loader==true">
			<div class="col-12 lg:col-12 mb-3">
				<ProgressBar mode="indeterminate" style="height: .5em" />
			</div>
		</template>
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card overview-box flex flex-column pt-1">
				<h4 class=" text-center align-items-center mb-6 mt-2">Movimientos de Transporte</h4>
				<Chart class="mt-2" type="bar" :data="barDataCustomers" :options="barOptions" />
			</div>
		</div>
		
		
		<div class="col-12 md:col-6 lg:col-6">
			<div class="card overview-box flex flex-column pt-1">
				<h4 class=" text-center align-items-center mt-2">Flota de confianza</h4>
				<template v-if="one_customer == false">
				<p class=" text-center">Debes seleccionar una empresa para poder visualizar placa por mes</p>
				</template>
				<template v-else>
					<p class=" text-center">|</p>
				</template>
				<Chart type="bar" :data="barDataFlota" :options="barOptionsFlota" />
			</div>
		</div>
		<div class="col-12 md:col-6 lg:col-6 mt-2">
			<div class="card overview-box flex flex-column pt-1">
				<h4 class=" text-center align-items-center mb-4 mt-2">Flete Empresa</h4>
				<Chart type="bar" :data="stackedData" :options="stackedOptions" />
			</div>
		</div>
		<div class="col-12 md:col-6 lg:col-6 mt-2">
			<div class="card overview-box flex flex-column pt-1">
				<h4 class=" text-center align-items-center mb-4 mt-2">Peso en Kilogramos</h4>
				<Chart type="bar" :data="barDataCustomersPeso" :options="barOptions" />
			</div>
		</div>
		<div class="col-12 md:col-6 lg:col-6 mt-2">
			<div class="card overview-box flex flex-column py-5 text-center align-items-center">
				<h4 class=" text-center align-items-center">Estados Movimientos de Transporte</h4>
				<template v-if="one_customer == true">
				<div class="field formgrid grid p-fluid pb-1">
					<div class="col-6 lg:col-6 my-3">
						<label><strong>Operativo</strong></label>
						<Chart type="doughnut" :data="pieDataOperative" :options="pieOptions" style="width: 220px;"/>
						
					</div>
					<div class="col-6 lg:col-6 my-3">
						<label><strong>Contable</strong></label>
						<Chart type="doughnut" :data="pieData" :options="pieOptions" style="width: 220px;"/>
					</div>
				</div>
				</template>
				<template v-else>
					<div class="my-5">
					<img src="layout/images/widgets/feature-faq.svg" alt="feature-faq" class="mb-2 mt-5 pt-5">
					<h5 class="pb-5 mb-5">Debes seleccionar una empresa para poder visualizar esta grafica</h5>
					</div>
				</template>
			</div>
		</div>
		<div class="col-12 md:col-6 lg:col-6 mt-2">
			<div class="card overview-box flex flex-column pt-3 pb-5 text-center align-items-center overflow-y">
				<h4 class=" text-center align-items-center mb-2">Productos Transportados</h4>
				<template v-if="one_customer == true">
					<div class="text-center align-items-center">
						<ScrollPanel style="width: 100%; height: 300px" class="text-center align-items-center">
							<Chart type="pie" :data="pieDataProduct" :options="pieOptions" style="width: 500px;"/>
						</ScrollPanel>
					</div>
						
				</template>
				<template v-else>
					<div class="my-5">
						<img src="layout/images/widgets/feature-faq.svg" alt="feature-faq" class="mb-5 mt-5 pt-5">
						<h5 class="pb-5 mb-5">Debes seleccionar una empresa para poder visualizar esta grafica</h5>
					</div>
					
				</template>
			</div>
		</div>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			customers_group: [],
			// productos: [],
			username:null,
			items:null,
			toggleMenu:null,
			isRTL:null,
			selectButtonValues1: [
					{name: 'Mes y Año', value: 'month'},
					{name: 'Año', value: 'year'},
				],
			barData: null,
			select_proveedor: null,
			filtro_mes: null,
			filtro_mes_rango: null,
			pieData: null,
			pieDataProduct: null,
			one_customer: false,
			pendiente: 0,
			enproceso: 0,
			cumplidos: 0,
			sin_facturar: 0,
			facturado: 0,
			pieDataOperative: null,
			pieOptions: null,
			value1: 'Mes y Año',
			options: ['Mes y Año', 'Año'],
			barDataCustomers: null,
			barDataCustomersPeso: null,
			barDataFlota: null,
			stackedData: null,
			barOptions: null,
			barOptionsFlota: null,
			labels_bar: [],
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
                cliente: '',
                direccion: '',
                mensajero: '',
                estadopedido: 0
            },
			loader: false,
			tipo_fecha: null,
			email:null,
			stackedOptions: {
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            
        },
			// stackedData: {
			// 	labels: [
			// 		"Enero",
			// 		"Febrero",
			// 		"Marzo",
			// 		"Abril",
			// 		"Mayo",
			// 		"Junio",
			// 		"Julio",
			// 		"Agosto",
			// 		"Septiembre",
			// 		"Octubre",
			// 		"Noviembre",
			// 		"Diciembre"
			// 	],
            //     datasets: [{
            //         type: 'bar',
            //         label: 'Dataset 1',
            //         backgroundColor: '#42A5F5',
            //         data: [50,25,12,48,90,76,42,22,55,63,8,49]
            //     }, {
            //         type: 'bar',
            //         label: 'Dataset 2',
            //         backgroundColor: '#66BB6A',
            //         data: [21,84,24,75,37,65,34,25,12,48,90,87]
            //     }, {
            //         type: 'bar',
            //         label: 'Dataset 3',
            //         backgroundColor: '#FFA726',
            //         data: [41,52,24,74,23,21,32,24,75,37,65,34]
            //     }]
            // },
			count: 0,
			ingresoCostumer: false
		}
	},
	mounted(){
		this.cargar_empresas();
		// this.tipo_fecha.code = "month";
		// this.tipo_fecha
		// this.barData = this.getBarData();
		// this.barDataCustomers = this.getBarDataCustomers();
		// this.barOptions = this.getBarChartOptions();
		// this.pieData = this.getPieData();
		// this.pieOptions = this.getPieChartOptions();
	},
	methods:{
		customer_selected(){
			// console.log(this.select_proveedor);
			if (this.select_proveedor!=null) {
				// this.load(0,1);
				// console.log("Si tiene");
				// this.load_indicadores = true;
			}else{

			}
		},
		refreshChart() {
		this.barData = this.getBarData();
		this.barDataCustomers = this.getBarDataCustomers();
		this.barDataCustomersPeso = this.getBarDataCustomersPeso();
		this.barDataFlota = this.getbarDataFlota();
		this.stackedData = this.getstackedData();
		this.barOptions = this.getBarChartOptions();
		this.barOptionsFlota = this.getBarChartOptionsflota();
		
		this.pieData = this.getPieData();
		this.pieDataOperative = this.getPieDataOperative();
		this.pieOptions = this.getPieChartOptions();
		this.pieDataProduct = this.getPieDataProduct();
		},
		limpiar(){
			this.select_proveedor=null;
			this.filtro_mes=null;
			this.cargar_empresas();
		},
		cargar_empresas(){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			// console.log(this.tipo_fecha);
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			// axios.post('/cargar_empresas', {email: email}).then(response => {
			// 	this.customers = response.data.data;
			// 	console.log(this.customers);
			// 	this.loader = false;
			// }).catch(e => {
				// console.log(id_rol);
			// });
			var nit_generador = null;
			var id_customer = null;
			var nombre_empresa = null;
			if (this.select_proveedor!=null) {
				nit_generador = this.select_proveedor.documento;
				id_customer = this.select_proveedor.id_customer;
				nombre_empresa = this.select_proveedor.nombre_empresa;
			}

			var _that = this;
			this.pendiente = 0;
			this.enproceso = 0;
			this.cumplidos = 0;
			this.sin_facturar = 0;
			this.facturado = 0;
			// this.productos = [];
			this.one_customer = false;
			var url = 'cargar_dashboard';
			window.api.call('post', url, {email: email,id_rol: id_rol,nit_generador: nit_generador,id_customer: id_customer,nombre_empresa: nombre_empresa,rango: this.frmfiltros.rangofechasolicitud,fecha: this.filtro_mes,fecha_fin: this.filtro_mes_rango,tipo_fecha: this.value1}).then( function(response) {
				if(response.status == 200){
					_that.customers = response.data.customer_totales;
					_that.customers_group = response.data.empresas;
					_that.pendiente = response.data.pendiente;
					_that.cumplidos = response.data.cumplidos;
					_that.enproceso = response.data.enproceso;
					_that.sin_facturar = response.data.sin_facturar;
					_that.facturado = response.data.facturado;
					// if ( response.data.productos) {
					// _that.productos = response.data.productos;
					// }
					// console.log(response.data.customer_selected);
					if (response.data.customer_selected==true) {
						// console.log("Selecciono un generador");
						_that.one_customer = true;
					}
					_that.refreshChart();
					// console.log(_that.barData);
					// response.data.empresas.forEach(element => {
					// 	_that.labels_bar.push(element.nombre_empresa)
						
					// });
					// _that.barData = _that.getBarData();
					// _that.barDataCustomers = _that.getBarDataCustomers();
					// _that.barOptions = _that.getBarChartOptions();
					// _that.pieData = _that.getPieData();
					// _that.pieOptions = _that.getPieChartOptions();
					_that.loader = false;
				}
			}).catch(function (err) {
				_that.loader = false;
				console.log(err);
				
			});
		},
		getBarData() {
			const isLight = this.$appState.layoutMode === 'light';
            const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
            const dataset2Color = isLight ? '#FF9800' : '#FFB74D';

            return {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'First Dataset',
                        backgroundColor: dataset1Color,
                        borderColor: dataset1Color,
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: 'Second Dataset',
                        backgroundColor: dataset2Color,
                        borderColor: dataset2Color,
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }
                ]
            };
        },
		getBarDataCustomers() {
            const isLight = this.$appState.layoutMode === 'light';
            const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
            const dataset2Color = isLight ? '#FF9800' : '#FFB74D';
			var datasets = [];
				this.customers.forEach(element => {
					// var randomColor = Math.floor(Math.random()*16777215).toString(16);
					datasets.push({
						label: element.nombre_empresa,
						// backgroundColor: '#'+randomColor,
						backgroundColor: element.color,
                        borderColor: dataset1Color,
                        data: [element.enero,
								element.febrero,
								element.marzo,
								element.abril,
								element.mayo,
								element.junio,
								element.julio,
								element.agosto,
								element.septiembre,
								element.octubre,
								element.noviembre,
								element.diciembre
								]
					})
					
				});
			
            return {
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre"
				],
				datasets: datasets
            };
        },
		getBarDataCustomersPeso() {
            const isLight = this.$appState.layoutMode === 'light';
            const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
            const dataset2Color = isLight ? '#FF9800' : '#FFB74D';
			var datasets = [];
				this.customers.forEach(element => {
					// var randomColor = Math.floor(Math.random()*16777215).toString(16);
					datasets.push({
						label: element.nombre_empresa,
						// backgroundColor: '#'+randomColor,
						backgroundColor: element.color,
                        borderColor: dataset1Color,
                        data: [element.enero_peso,
								element.febrero_peso,
								element.marzo_peso,
								element.abril_peso,
								element.mayo_peso,
								element.junio_peso,
								element.julio_peso,
								element.agosto_peso,
								element.septiembre_peso,
								element.octubre_peso,
								element.noviembre_peso,
								element.diciembre_peso
								]
					})
					
				});
			
            return {
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre"
				],
				datasets: datasets
            };
        },
		getbarDataFlota() {
            const isLight = this.$appState.layoutMode === 'light';
            const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
            const dataset2Color = isLight ? '#FF9800' : '#FFB74D';
			var datasets = [];
			var labels_flota = [];
				this.customers.forEach(element => {
					// var randomColor = Math.floor(Math.random()*16777215).toString(16);
					datasets.push({
						label: element.nombre_empresa,
						// backgroundColor: '#'+randomColor,
						backgroundColor: element.color,
                        borderColor: dataset1Color,
                        data: [element.enero,
								element.febrero,
								element.marzo,
								element.abril,
								element.mayo,
								element.junio,
								element.julio,
								element.agosto,
								element.septiembre,
								element.octubre,
								element.noviembre,
								element.diciembre
								]
					});

					var nombre_enero = '';
					var nombre_febrero = '';
					var nombre_marzo = '';
					var nombre_abril = '';
					var nombre_mayo = '';
					var nombre_junio = '';
					var nombre_julio = '';
					var nombre_agosto = '';
					var nombre_septiembre = '';
					var nombre_octubre = '';
					var nombre_noviembre = '';
					var nombre_diciembre = '';

					// switch (element.mes) {
					// 	case 1:
					// 		nombre_enero = element.enero_placa ?? '';
					// 		break;
					// 	case 2:
					// 		nombre_febrero = element.febrero_placa;
					// 		break;
					// 	case 3:
					// 		nombre_marzo = element.marzo_placa;
					// 		break;
					// 	case 4:
					// 		nombre_abril = element.abril_placa;
					// 		break;
					// 	case 5:
					// 		nombre_mayo = element.mayo_placa;
					// 		break;
					// 	case 6:
					// 		nombre_junio = element.junio_placa;
					// 		break;
					// 	case 7:
					// 		nombre_julio = element.julio_placa;
					// 		break;
					// 	case 8:
					// 		nombre_agosto = element.agosto_placa;
					// 		break;
					// 	case 9:
					// 		nombre_septiembre = element.septiembre_placa;
					// 		break;
					// 	case 10:
					// 		nombre_octubre = element.octubre_placa;
					// 		break;
					// 	case 11:
					// 		nombre_noviembre = element.noviembre_placa;
					// 		break;
					// 	case 12:
					// 		nombre_diciembre = element.diciembre_placa;
					// 		break;

					// 	default:
							nombre_enero = element.enero_placa ?? '';
							nombre_febrero = element.febrero_placa ?? '';
							nombre_marzo = element.marzo_placa ?? '';
							nombre_abril = element.abril_placa ?? '';
							nombre_mayo = element.mayo_placa ?? '';
							nombre_junio = element.junio_placa ?? '';
							nombre_julio = element.julio_placa ?? '';
							nombre_agosto = element.agosto_placa ?? '';
							nombre_septiembre = element.septiembre_placa ?? '';
							nombre_octubre = element.octubre_placa ?? '';
							nombre_noviembre = element.noviembre_placa ?? '';
							nombre_diciembre = element.diciembre_placa ?? '';
					// 		break;
					// }
					
					labels_flota = [
						"Enero | "+nombre_enero,
						"Febrero | "+nombre_febrero,
						"Marzo | "+nombre_marzo,
						"Abril | "+nombre_abril,
						"Mayo | "+nombre_mayo,
						"Junio | "+nombre_junio,
						"Julio | "+nombre_julio,
						"Agosto | "+nombre_agosto,
						"Septiembre | "+nombre_septiembre,
						"Octubre | "+nombre_octubre,
						"Noviembre | "+nombre_noviembre,
						"Diciembre | "+nombre_diciembre
						// "Enero | "+element.enero_placa,
						// "Febrero | "+element.febrero_placa,
						// "Marzo | "+element.marzo_placa,
						// "Abril | "+element.abril_placa,
						// "Mayo | "+element.mayo_placa,
						// "Junio | "+element.junio_placa,
						// "Julio | "+element.julio_placa,
						// "Agosto | "+element.agosto_placa,
						// "Septiembre | "+element.septiembre_placa,
						// "Octubre | "+element.octubre_placa,
						// "Noviembre | "+element.noviembre_placa,
						// "Diciembre | "+element.diciembre_placa
					]
					console.log(labels_flota);
				});
				console.log(labels_flota);
			// }
			

            return {
                labels: labels_flota,
				// labels: [
				// 	"Enero",
				// 	"Febrero",
				// 	"Marzo",
				// 	"Abril",
				// 	"Mayo",
				// 	"Junio",
				// 	"Julio",
				// 	"Agosto",
				// 	"Septiembre",
				// 	"Octubre",
				// 	"Noviembre",
				// 	"Diciembre"
				// ],
				datasets: datasets
            };
        },
		getstackedData() {
            const isLight = this.$appState.layoutMode === 'light';
            const dataset1Color = isLight ? '#00ACC1' : '#4DD0E1';
            const dataset2Color = isLight ? '#FF9800' : '#FFB74D';
			var datasets = [];
				this.customers.forEach(element => {
					// var randomColor = Math.floor(Math.random()*16777215).toString(16);
					datasets.push({
						label: element.nombre_empresa,
						// backgroundColor: '#'+randomColor,
						backgroundColor: element.color,
                        borderColor: dataset1Color,
                        data: [element.enero_flete_empresa,
								element.febrero_flete_empresa,
								element.marzo_flete_empresa,
								element.abril_flete_empresa,
								element.mayo_flete_empresa,
								element.junio_flete_empresa,
								element.julio_flete_empresa,
								element.agosto_flete_empresa,
								element.septiembre_flete_empresa,
								element.octubre_flete_empresa,
								element.noviembre_flete_empresa,
								element.diciembre_flete_empresa
								]
						// data: [element.flete_empresa.enero ?? 0,
						// 		element.flete_empresa.febrero ?? 0,
						// 		element.flete_empresa.marzo ?? 0,
						// 		element.flete_empresa.abril ?? 0,
						// 		element.flete_empresa.mayo ?? 0,
						// 		element.flete_empresa.junio ?? 0,
						// 		element.flete_empresa.julio ?? 0,
						// 		element.flete_empresa.agosto ?? 0,
						// 		element.flete_empresa.septiembre ?? 0,
						// 		element.flete_empresa.octubre ?? 0,
						// 		element.flete_empresa.noviembre ?? 0,
						// 		element.flete_empresa.diciembre ?? 0
						// 		]
					})
					
				});
				// console.log(datasets);
			// }
			

            return {
                // labels: labels,
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre"
				],
				datasets: datasets
				// labels: [
				// 	"Enero",
				// 	"Febrero",
				// 	"Marzo",
				// 	"Abril",
				// 	"Mayo",
				// 	"Junio",
				// 	"Julio",
				// 	"Agosto",
				// 	"Septiembre",
				// 	"Octubre",
				// 	"Noviembre",
				// 	"Diciembre"
				// ],
                // datasets: [{
                //     type: 'bar',
                //     label: 'Dataset 1',
                //     backgroundColor: '#42A5F5',
                //     data: [50,25,12,48,90,76,42,22,55,63,8,49]
                // }, {
                //     type: 'bar',
                //     label: 'Dataset 2',
                //     backgroundColor: '#66BB6A',
                //     data: [21,84,24,75,37,65,34,25,12,48,90,87]
                // }, {
                //     type: 'bar',
                //     label: 'Dataset 3',
                //     backgroundColor: '#FFA726',
                //     data: [41,52,24,74,23,21,32,24,75,37,65,34]
                // }]
            };
        },
		getPieData() {
            const { limeColor, pinkColor, brownColor, orangeColor } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

            return {
                labels: ['SIN FACTURAR', 'FACTURADO'],
                datasets: [
                    {
                        data: [this.sin_facturar, this.facturado],
                        backgroundColor: [
                            orangeColor,
                            brownColor
                        ],
                        borderColor
                    }]
            };
        },
		getPieDataProduct() {
            const {
				limeColor,
				blueColor,
				tealColor,
				pinkColor,
				orangeColor,
				brownColor,
				purpleColor,
				yellowColor,
				amberColor  
				  } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

			var enero = 0;
			var febrero = 0;
			var marzo = 0;
			var abril = 0;
			var mayo = 0;
			var junio = 0;
			var julio = 0;
			var agosto = 0;
			var septiembre = 0;
			var octubre = 0;
			var noviembre = 0;
			var diciembre = 0;

			var nombre_enero = '';
			var nombre_febrero = '';
			var nombre_marzo = '';
			var nombre_abril = '';
			var nombre_mayo = '';
			var nombre_junio = '';
			var nombre_julio = '';
			var nombre_agosto = '';
			var nombre_septiembre = '';
			var nombre_octubre = '';
			var nombre_noviembre = '';
			var nombre_diciembre = '';
			var datasets = [];
			// if (this.productos.length>0) {
				// console.log(this.productos);
				// this.productos.forEach(element => {
					
				// 	switch (element.mes) {
				// 		case 1:
				// 			enero = element.total;
				// 			nombre_enero = element.producto_descripcion;
				// 			break;
				// 		case 2:
				// 			febrero = element.total;
				// 			nombre_febrero = element.producto_descripcion;
				// 			break;
				// 		case 3:
				// 			marzo = element.total;
				// 			nombre_marzo = element.producto_descripcion;
				// 			break;
				// 		case 4:
				// 			abril = element.total;
				// 			nombre_abril = element.producto_descripcion;
				// 			break;
				// 		case 5:
				// 			mayo = element.total;
				// 			nombre_mayo = element.producto_descripcion;
				// 			break;
				// 		case 6:
				// 			junio = element.total;
				// 			nombre_junio = element.producto_descripcion;
				// 			break;
				// 		case 7:
				// 			julio = element.total;
				// 			nombre_julio = element.producto_descripcion;
				// 			break;
				// 		case 8:
				// 			agosto = element.total;
				// 			nombre_agosto = element.producto_descripcion;
				// 			break;
				// 		case 9:
				// 			septiembre = element.total;
				// 			nombre_septiembre = element.producto_descripcion;
				// 			break;
				// 		case 10:
				// 			octubre = element.total;
				// 			nombre_octubre = element.producto_descripcion;
				// 			break;
				// 		case 11:
				// 			noviembre = element.total;
				// 			nombre_noviembre = element.producto_descripcion;
				// 			break;
				// 		case 12:
				// 			diciembre = element.total;
				// 			nombre_diciembre = element.producto_descripcion;
				// 			break;

				// 		default:
				// 			break;
				// 	}
				// });
				
				this.customers.forEach(element => {

					nombre_enero = element.enero_producto ?? '';
							nombre_febrero = element.febrero_producto ?? '';
							nombre_marzo = element.marzo_producto ?? '';
							nombre_abril = element.abril_producto ?? '';
							nombre_mayo = element.mayo_producto ?? '';
							nombre_junio = element.junio_producto ?? '';
							nombre_julio = element.julio_producto ?? '';
							nombre_agosto = element.agosto_producto ?? '';
							nombre_septiembre = element.septiembre_producto ?? '';
							nombre_octubre = element.octubre_producto ?? '';
							nombre_noviembre = element.noviembre_producto ?? '';
							nombre_diciembre = element.diciembre_producto ?? '';
					
					// switch (element.mes) {
					// 	case 1:
					// 		enero = element.total;
					// 		nombre_enero = element.enero_producto;
					// 		break;
					// 	case 2:
					// 		febrero = element.total;
					// 		nombre_febrero = element.producto_descripcion;
					// 		break;
					// 	case 3:
					// 		marzo = element.total;
					// 		nombre_marzo = element.producto_descripcion;
					// 		break;
					// 	case 4:
					// 		abril = element.total;
					// 		nombre_abril = element.producto_descripcion;
					// 		break;
					// 	case 5:
					// 		mayo = element.total;
					// 		nombre_mayo = element.producto_descripcion;
					// 		break;
					// 	case 6:
					// 		junio = element.total;
					// 		nombre_junio = element.producto_descripcion;
					// 		break;
					// 	case 7:
					// 		julio = element.total;
					// 		nombre_julio = element.producto_descripcion;
					// 		break;
					// 	case 8:
					// 		agosto = element.total;
					// 		nombre_agosto = element.producto_descripcion;
					// 		break;
					// 	case 9:
					// 		septiembre = element.total;
					// 		nombre_septiembre = element.producto_descripcion;
					// 		break;
					// 	case 10:
					// 		octubre = element.total;
					// 		nombre_octubre = element.producto_descripcion;
					// 		break;
					// 	case 11:
					// 		noviembre = element.total;
					// 		nombre_noviembre = element.producto_descripcion;
					// 		break;
					// 	case 12:
					// 		diciembre = element.total;
					// 		nombre_diciembre = element.producto_descripcion;
					// 		break;

					// 	default:
					// 		break;
					// }
					datasets.push({
						// label: element.producto_descripcion,
						label: [
							nombre_enero,
							nombre_febrero,
							nombre_marzo,
							nombre_abril,
							nombre_mayo,
							nombre_junio,
							nombre_julio,
							nombre_agosto,
							nombre_septiembre,
							nombre_octubre,
							nombre_noviembre,
							nombre_diciembre,
						],
						backgroundColor: [
                            blueColor,
                            tealColor,
                            limeColor,
							pinkColor,
                            orangeColor,
                            brownColor,
							purpleColor,
                            yellowColor,
                            amberColor
                        ],
						data: [element.enero,
								element.febrero,
								element.marzo,
								element.abril,
								element.mayo,
								element.junio,
								element.julio,
								element.agosto,
								element.septiembre,
								element.octubre,
								element.noviembre,
								element.diciembre
								]
					});
				});
			// }
					// var randomColor = Math.floor(Math.random()*16777215).toString(16);
					
					

            return {
                labels: [
					"Enero - "+nombre_enero,
					"Febrero - "+nombre_febrero,
					"Marzo - "+nombre_marzo,
					"Abril - "+nombre_abril,
					"Mayo - "+nombre_mayo,
					"Junio - "+nombre_junio,
					"Julio - "+nombre_julio,
					"Agosto - "+nombre_agosto,
					"Septiembre - "+nombre_septiembre,
					"Octubre - "+nombre_octubre,
					"Noviembre - "+nombre_noviembre,
					"Diciembre - "+nombre_diciembre,
				],
				
				datasets: datasets
                // datasets: [
                //     {
                //         data: [this.sin_facturar, this.facturado],
                //         backgroundColor: [
                //             orangeColor,
                //             brownColor
                //         ],
                //         borderColor
                //     }]
            };
        },
		getPieDataOperative() {
            const { limeColor, blueColor, tealColor, orangeColor } = this.getColors();
            const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

            return {
                labels: ['PENDIENTE POR ASIGNACIÓN', 'EN PROCESO', 'CUMPLIDO'],
                // labels: ['A', 'B', 'C', 'D'],
                datasets: [
                    {
                        data: [this.pendiente, this.enproceso, this.cumplidos],
                        backgroundColor: [
                            blueColor,
                            tealColor,
                            limeColor
                        ],
                        borderColor
                    }]
            };
        },
		getBarChartOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

            return {
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor,
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    },
                    y: {
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    }
                }
            }
        },
		getBarChartOptionsflota() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

            return {
				indexAxis: 'y',
                plugins: {
                    legend: {
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor,
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    },
                    y: {
                        ticks: {
                            font: {
                                family: fontFamily
                            },
                            color: textColor
                        },
                        grid: {
                            color: gridLinesColor
                        }
                    }
                }
            }
        },
		getPieChartOptions() {
            const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
            return {
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            font: {
                                family: fontFamily
                            },
                            fontColor: textColor,
                        }
                    }
                }
            }
        },
		getColors() {
            const isLight = this.$appState.layoutMode === 'light';
            return {
                pinkColor: isLight ? '#EC407A' : '#F48FB1',
                purpleColor: isLight ? '#AB47BC' : '#CE93D8',
                deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
                indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
                blueColor: isLight ? '#42A5F5' : '#90CAF9',
                lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
                cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
                tealColor: isLight ? '#26A69A' : '#80CBC4',
                greenColor: isLight ? '#66BB6A' : '#A5D6A7',
                lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
                limeColor: isLight ? '#D4E157' : '#E6EE9C',
                yellowColor: isLight ? 'FFEE58' : '#FFF59D',
                amberColor: isLight ? '#FFCA28' : '#FFE082',
                orangeColor: isLight ? '#FFA726' : '#FFCC80',
                deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
                brownColor: isLight ? '#8D6E63' : '#BCAAA4'
            }
        },
		salirCustomer(){
			this.ingresoCostumer = false;
		},
	}
}
</script>

<style scoped>
/* p{
	color: #00ACC1;
	background: #4DD0E1;
} */
</style>
